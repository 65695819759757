import React, { useEffect, useState } from "react";
import moment from "moment";
import Map from "../../components/selfHelpGds/map";
import MarketingBar from "../../components/marketingBar";
import ReturnCoupon from "../../components/selfHelp/trayContent/couponTimer";

import cx from "classnames";
import {
  fetchGDSBooking,
  fetchMarketingCards,
  gdsLastLocation
} from "../../network";
import styles from "./gdsSelfhelp.module.scss";
import signupImage from "../../images/signupImage.png";
import waitingImage from "../../images/waiting.svg";
import completedImage from "../../images/completed.svg";
import { marketingCardsPriority } from "../../config";
import { message, Modal } from "antd";
import Fetch from "../../utils/fetch";

const bannerConstants = {
  ACTIVE: {
    image: waitingImage,
    text: "Your tracking page is not active yet"
  },
  COMPLETED: {
    image: completedImage,
    text: "Your Trip has been Completed.<br/>Thank You for riding with Zingbus."
  }
};

const getBannerByTripStatus = tripStatus => {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.mb10}>
        <img src={bannerConstants[tripStatus].image} />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: bannerConstants[tripStatus].text }}
      />
    </div>
  );
};

const SelfHelp = props => {
  const [loading, updateLoading] = useState(true);
  const [booking, updateBookingData] = useState({});
  const [marketingCardsData, updateMarketingCardsData] = useState({});
  const [lastLocation, updateLastLocation] = useState({});
  const [tripStatus, updateTripStatus] = useState("ACTIVE");
  const [showSOSModal, setSOSModal] = useState(false);

  useEffect(() => {
    getFirstLoadData();
  }, []);

  const isRedirectRequired = () => {
    const query = new URLSearchParams(props.location.search);
    return query.get("initiator") == "B2CANDROID";
  };

  const getFirstLoadData = async () => {
    try {
      const bookingCode = props.match.params.bookingCode;
      const gdsBooking = await getBooking(bookingCode);

      const tripId = gdsBooking.gdsOperatorTrip._id;

      await getLastLocation(tripId);
      updateLoading(false);
      getMarketingCards();
    } catch (err) {
      console.log(err);
    }
  };

  const getMarketingCards = async () => {
    try {
      const path = "/marketing/selfHelpPage?platform=SELFHELPPAGE";
      const marketingCards = await fetchMarketingCards(path, {});

      updateMarketingCardsData(marketingCards);
    } catch (err) {
      console.log(err);
    }
  };

  const getLastLocation = async tripId => {
    try {
      const lastLocation = await gdsLastLocation(tripId, {});

      if (lastLocation && lastLocation.latlngDate) {
        lastLocation.readableTime = moment(lastLocation.latlngDate).format(
          "hh:mm A"
        );
      }

      updateLastLocation(lastLocation);

      return lastLocation;
    } catch (err) {
      console.log(err);
    }
  };

  const getBooking = async bookingCode => {
    try {
      const bookingQuery = `bookingCode=${bookingCode}`;

      const gdsBookingArr = await fetchGDSBooking(bookingQuery, {});

      const gdsBooking = gdsBookingArr[0];

      gdsBooking.fromStationTime = moment(gdsBooking.fromStationDate).format(
        "hh:mm a | D MMM"
      );

      gdsBooking.toStationTime = moment(gdsBooking.toStationDate).format(
        "hh:mm a| D MMM"
      );

      let allSeatsLabelStr = "";

      for (let i = 0; i < gdsBooking.tripChart.length; ++i) {
        const { seatLabel } = gdsBooking.tripChart[i];

        allSeatsLabelStr += seatLabel;

        if (i + 1 < gdsBooking.tripChart.length) {
          allSeatsLabelStr += ", ";
        }
      }

      gdsBooking.tripId = gdsBooking.gdsOperatorTrip._id;

      let tripStatus = "ACTIVE";
      if (
        gdsBooking.gdsOperatorTrip &&
        gdsBooking.gdsOperatorTrip.busAssignmentDetails
      ) {
        tripStatus = "ONTRIP";
      }

      if (Date.now() > gdsBooking.toStationDate + 2 * 60 * 60 * 1000) {
        tripStatus = "COMPLETED";
      }

      gdsBooking.allSeatsLabelStr = allSeatsLabelStr;

      updateBookingData(gdsBooking);
      updateTripStatus(tripStatus);
      return gdsBooking;
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const callMarshall = () => {
    let number;
    if (booking.ivrDidNo && booking.gdsMaskingEnabled) {
      number = 8929049595;
    } else {
      number =
        booking.gdsOperatorTrip && booking.gdsOperatorTrip.busAssignmentDetails
          ? booking.gdsOperatorTrip.busAssignmentDetails.busHostPhoneNumber
            ? parseInt(
                booking.gdsOperatorTrip.busAssignmentDetails.busHostPhoneNumber
              )
            : parseInt(
                booking.gdsOperatorTrip.busAssignmentDetails.driverPhoneNumber
              )
          : null;
    }
    if (number == null) {
      message.error("Sorry! no bus is assigned");
      return;
    } else {
      window.open(`tel:+91-${number}`);
    }
  };

  const openWhatsApp = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=918287009889&text=zingbus"
    );
  };

  const raiseSOS = async () => {
    try {
      let url = `/gds/booking/raiseSOS/`;

      const options = {
        method: "post",
        data: {
          bookingCode: booking.bookingCode
        }
      };

      const response = await Fetch(url, options);
      if (Array.isArray(response) && response.length > 0) {
        let gdsbooking = { ...booking, isSOSAlertInserted: true };
        updateBookingData(gdsbooking);
        setSOSModal(false);
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error(err);
    }
  };

  const isAndroid = isRedirectRequired();
  const ua = navigator.userAgent.toLowerCase();
  const isIos = ua.match(/iPhone|iPad|iPod/i);
  const busNumber =
    booking.gdsOperatorTrip && booking.gdsOperatorTrip.busAssignmentDetails
      ? booking.gdsOperatorTrip.busAssignmentDetails.busRc
      : null;

  const fromStationName = booking.fromStationName;
  const fromStationTime = booking.fromStationTime;

  const toStationAddress = booking.toStationAddress;
  const toStationTime = booking.toStationTime;

  // let coupontime, hrs, min;
  // if (booking.returnCouponDetail) {
  //   const deadline = booking.toStationTime;
  //   coupontime = deadline - Date.now();

  //   hrs = Math.floor((coupontime / (1000 * 60 * 60)) % 24);
  //   min = Math.floor((coupontime / 1000 / 60) % 60);
  // }

  return (
    <div className={styles.selfHelpContainer}>
      <div className={styles.header}>
        <span>Track Your Bus</span>

        <div
          onClick={() =>
            booking.isSOSAlertInserted ? null : setSOSModal(!showSOSModal)
          }
          className={
            booking.isSOSAlertInserted ? styles.sosInitiated : styles.sos
          }
        >
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/test/selfhelp/SOS.svg"
            alt="bell"
          />
          <span>{booking.isSOSAlertInserted ? "SOS Initiated" : "SOS"}</span>
        </div>
      </div>
      <Modal
        centered
        width={"80%"}
        visible={showSOSModal}
        footer={null}
        onCancel={() => setSOSModal(!showSOSModal)}
      >
        <div className={styles.SOSModal}>
          <p>Initiate SOS </p>
          <p>Are you sure you want to initiate SOS?</p>
          <p>
            Initiate SOS services only in case of emergency. Once SOS is
            initiated our team will try to reach you as soon as possible
          </p>
          <div onClick={() => raiseSOS()}>
            <span>Inititae SOS</span>
          </div>
        </div>
      </Modal>
      <div className={styles.seatDetails}>
        <div>PNR: {booking.bookingCode}</div>
        <div>
          {booking.tripChart.length} Seats ({booking.allSeatsLabelStr})
        </div>
      </div>
      <div className={styles.mapContainer}>
        {tripStatus === "ONTRIP" ? (
          <>
            <Map
              height={lastLocation.coordinates ? "calc(50vh - 44px)" : "50vh"}
              busPosition={lastLocation.coordinates}
              showFullScreenButton={!!lastLocation.coordinates && !isIos}
              showPinButton={!!lastLocation.coordinates}
              tripId={booking.tripId}
              updateLastLocation={updateLastLocation}
            />
            {lastLocation.coordinates ? (
              <div className={styles.lastUpdatedAtBanner}>
                Last updated at {lastLocation.readableTime}
              </div>
            ) : null}
          </>
        ) : (
          <div>{getBannerByTripStatus(tripStatus)}</div>
        )}
      </div>
      <div className={styles.contentContainer}>
        <div style={{ padding: "2px 10px" }}>
          {booking.returnCouponDetail ? (
            <ReturnCoupon
              booking={booking}
              // hrs={hrs}
              // mins={min}
              couponDetails={booking.returnCouponDetail}
            />
          ) : null}
        </div>

        {busNumber ? (
          <>
            <div className={styles.busDetails}>
              <h1>{busNumber}</h1>&nbsp;
              <span>(Bus Number)</span>
            </div>
            <hr className={styles.divider} />
          </>
        ) : null}
        <div className={styles.stationDetails}>
          <div className={styles.mb20}>
            <div className={styles.stationHeading}>
              <span>
                <img
                  src="/static/media/pickup_icon.e6422694.svg"
                  alt="Boarding point"
                />
              </span>
              <h2>Boarding Point</h2>
            </div>
            <div className={styles.stationAddress}>{fromStationName}</div>
            <div className={styles.stationTime}>{fromStationTime}</div>
          </div>
          <div>
            <div className={styles.stationHeading}>
              <span>
                <img
                  src="/static/media/drop_icon.e9b77cdb.svg"
                  alt="Drop Point"
                />
              </span>
              <h2>Drop Point</h2>
            </div>
            <div className={styles.stationAddress}>{toStationAddress}</div>
            <div className={styles.stationTime}>{toStationTime}</div>
          </div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.buttonContainer}>
          <button className={styles.marshalCallButton} onClick={callMarshall}>
            <span>
              <img
                src="/static/media/phoneImage.a723cceb.svg"
                alt="Call Marshal"
              />
            </span>
            Call Marshal
          </button>
          <button className={styles.supportCallButton} onClick={openWhatsApp}>
            <span>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZRNSFRRFMf/584Ho1JO0aIvctzkOGAIQbQQinYtgmhXLbRNBC3STX6M5JPGQRemLvrYBApFuAhaFbRRCNdNUGoE4wRSUAstogYd3+l/p3kyDjPNRAfe3DfnnvO75+tdQQUZbNHmnMENIzgniggERoEUt1Kui+HRZcmU8zPllPGY9qkPaZ+gjZAEocdoeJLgO1z9PoOVgagOlfOVUsVATIeo7BKDK4m3Ml/hwBNcZtXFTHJZnIpAnmpB4wEXx50KKXniRDXCkrxWxbWRJZlF2ZQFQzTorQbLA2njApP0GSnWbwOZajs3PyeX8BA1SjCIKab4yUa7HVN3RMOhEML2T2g/Vp15ydl3q7frZEbWS0FXD2j9njoExtLyrdg/m8W6aahHxnbNPptfMG034lHtpH6Fz1p/q04UwyxgXxgL/hDTpdTX4b7nT/uPNuXGInu//VGBA8Uwm9PM0enqjWmHByNgTgTtnoPubOzusnPIuZvhHD7ZRH4Ow74tBG626K4CDGzcFCpIWWBiURxW8gzPfkZAX/K9zAV86MlHppihvonrQa/OKE2xVPqjeopOj/hl3E68k7FCbt9ZilfUX2CQRzivjaEA9tYEZCTTdFx2JT9Ojm7gLofXNiHfCKvjAU2jHyTNplUHEvaUDofJi/I9F/Sjgdqv3rZxMe8aXhjI1zu3w3WwTc+7W/BqkUouSgr/IHaoNwxO/4kDa4L/kD7CCLiV/YHrE6vyy+r8tTjx6G7W1cfbZdy7B+NH9RD1L/kseLCagMbgHqM4a9/VoKM/pg84h60EdbIdb4I/0VNsXxXIyOpQaCQbEHEVFzm8GY7UZXb+eal9VSAjiRP4mGuYsEts2ou/mf8GlKHgPJcXbB0AAAAASUVORK5CYII="
                alt="Support"
              />
            </span>
            <span>
              Support <br />
              <span>Get reply within 30 sec</span>
            </span>
          </button>
        </div>
        <hr className={styles.divider} />
        {!isAndroid ? (
          <div className={styles.trayCards}>
            <img src={signupImage} />
          </div>
        ) : null}
        <p className={styles.offerHeader}>BEST OFFERS FOR YOUR JOURNEY</p>
        {marketingCardsPriority.map(key =>
          marketingCardsData[key] ? (
            <div
              key={key}
              className={cx(
                styles.marketingCardsData,
                styles.transparentBackground,
                "p-0"
              )}
            >
              <MarketingBar booking={booking} data={marketingCardsData[key]} />
            </div>
          ) : (
            <div key={key} />
          )
        )}
      </div>
    </div>
  );
};

export default SelfHelp;

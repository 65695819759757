import React, { Component, useState } from "react";
import cx from "classnames";
import { message, BackTop, Modal } from "antd";
import moment from "moment";
import Fetch from "../../utils/fetch";
import OverlayScreen from "../../components/selfHelp/overlayScreen";
import StationChangeScreen from "../../components/selfHelp/stationChangeScreen";
import OldOverlayScreen from "../../components/selfHelp/oldoverlayScreen";
import OldHeader from "../../components/Oldheader";
import OldMap from "../../components/selfHelp/oldMap";
import OldTripDetails from "../../components/selfHelp/oldTrayContent/tripDetails";
import OldScreen from "../../components/selfHelp/oldScreen";
import Screen from "../../components/selfHelp/screen";
import Loader from "../../components/loader";
import Header from "../../components/header";
import Footer from "../../components/customerLinkFooter";
import Map from "../../components/selfHelp/map";
import QRCode from "../../components/selfHelp/trayContent/qrCode";
import CrewDetails from "../../components/selfHelp/trayContent/crewDetails";
import Emergency from "../../components/selfHelp/trayContent/emergency";
import Utils from "../../components/selfHelp/trayContent/utils";
import TripDetails from "../../components/selfHelp/trayContent/tripDetails";
import ReturnCoupon from "../../components/selfHelp/trayContent/couponTimer";
import OnTimeBanner from "../../components/selfHelp/trayContent/OTG";

import MarketingBar from "../../components/marketingBar";
import { CaretUpOutlined } from "@ant-design/icons";
import BusMissed from "../../components/busMissed";
import signupImage from "../../images/signupImage.png";
import CovidConsentForm from "../../components/covidConsentForm";
import {
  getWayPoints,
  getStationFromStationId,
  isDeviceAndroid
} from "../../utils/common";
import AppRedirectScreen from "../../components/appRedirectScreen";
import TripCompleted from "../../components/tripCompleted";
import OpenModal from "../../components/openModal";
import mixpanel from "mixpanel-browser";

import {
  marketingCardsPriority,
  majnuKaTilaId,
  servicesToCheck,
  foodCarouselId,
  mixPanelToken,
  isStage
} from "../../config";
//check
import {
  fetchLastLocation,
  fetchBooking,
  fetchStation,
  fetchBusRoute,
  fetchBusType,
  fetchCities
} from "../../network";

import styles from "./selfhelp.module.scss";

import { googleLayerAnalytics } from "../../../src/utils/googleDataLayer";

// const [isModalVisible, setIsModalVisible] = useState(false);
class SelfHelp extends Component {
  state = {
    loading: true,
    bookingId: this.props.match.params.bookingId,
    busMissed: false,
    booking: {},
    busRoute: [],
    eta: {},
    marketingCardsData: {},
    upcomingStation: {},
    tripStatus: "",
    isAndroid: false,
    lastlocation: {},
    openOverlayScreen: false,
    overlayScreenContent: null,
    overlayTitle: "",
    selectedStationOverlay: "",
    bus: {},
    busCatchPossible: false,
    boardingStationMarked: false,
    busHost: {},
    cities: [],
    connectingFlag: false,
    appRedirect: false,
    busStartTime: null,
    tourValue: 0,
    isConnectingBooking: false,
    connectingFirstLegCompleted: false,
    setIsModalVisible: false,
    TrackingWorking: true,
    isCheckService: true
  };

  handleOk = () => {
    this.setState({ setIsModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ setIsModalVisible: false });
  };
  openPlayStore = () => {
    const { booking } = this.state;
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Signup Clicked", {
      mobile_no: booking.mobileNo,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    googleLayerAnalytics("download_app_click", {
      mobile_no: booking.mobileNo,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") !== -1;
    if (isAndroid) {
      window.open(
        `https://play.google.com/store/apps/details?id=com.zingbusbtoc.zingbus`
      );
    } else {
      window.open(
        `https://apps.apple.com/in/app/zingbus-book-bus-ticket-online/id1600049529`
      );
    }
  };
  async componentDidMount() {
    this.fetchData();

    this.interval = setInterval(() => {
      this.checkIsTrackingWorking();
    }, 10 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  checkIsTrackingWorking = () => {
    const { lastDataRecievedAt } = this.state;
    if (lastDataRecievedAt < Date.now() - 10 * 60 * 1000) {
      this.setState({ TrackingWorking: false });
    }
  };

  checkLastTime = data => {
    this.setState({ lastDataRecievedAt: data });
  };

  fetchData = async bookingId => {
    try {
      let bookingPromise = [];
      if (bookingId == undefined) {
        const { bookingId } = this.state;
        bookingPromise = fetchBooking(null, bookingId);
      } else {
        bookingPromise = fetchBooking(null, bookingId);
      }
      const stateObj = { loading: false };
      const d = new Date(); // time lies between 11 am to 11 pm
      const hours = d.getHours();
      this.getCustomerLatLng();
      const marketingCardsPromise = this.getMarketingCards();

      let [booking, marketingCardsData, cities] = await Promise.all([
        bookingPromise,
        marketingCardsPromise,
        fetchCities()
      ]);
      if (servicesToCheck.includes(booking.trip.serviceId)) {
        stateObj.isCheckService = true;
        // this.setState({ isCheckService: true });
      }
      if (booking.connectingBookingPnr) {
        this.setState({ isConnectingBooking: true });
        if (booking.trip.status == "COMPLETED") {
          booking = await fetchBooking(null, booking.connectingBooking.id);
          this.setState({
            connectingFirstLegCompleted: true,
            isConnectingBooking: true
          });
        }
      }

      window.onpopstate = () => {
        window.location.reload();
      };
      const station = await fetchStation(booking.tripId);
      // google dataLayer
      googleLayerAnalytics("page_view", {
        mobile_no: booking.mobileNo,
        pnr: booking.bookingCode,
        from_city: booking.boardingPoint.city,
        to_city: booking.dropPoint.city,
        from_station: booking.boardingPoint.address,
        to_station: booking.dropPoint.address,
        trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
        booking_source: booking.demandSource,
        open_date: moment(new Date()).format("YYYY-MM-DD"),
        seat_label: booking.tripChart ? booking.tripChart[0].seatLabel : null
      });
      mixpanel.init(mixPanelToken, { debug: isStage });
      mixpanel.track("Self Help Page Viewed", {
        mobile_no: booking.mobileNo,
        pnr: booking.bookingCode,
        from_city: booking.boardingPoint.city,
        to_city: booking.dropPoint.city,
        from_station: booking.boardingPoint.address,
        to_station: booking.dropPoint.address,
        booking_source: booking.demandSource
      });
      stateObj.station = station;
      stateObj.booking = booking;
      stateObj.marketingCardsData = marketingCardsData;
      stateObj.cities = cities;

      const tripDetails = await fetchBusRoute(booking.tripId);

      // tripStatus contains 4 status ["ACTIVE", "ASSIGNED", "ONTRIP", "COMPLETED"] (This tripstatus is only for self help page internal use)
      stateObj.tripStatus = tripDetails.status;
      stateObj.busRoute = tripDetails.busRoute;
      stateObj.busStartTime = tripDetails.busRoute[0].reachingTime;

      const { bus = {}, busHost = {}, busDriver = {} } = tripDetails;
      stateObj.bus = bus;
      stateObj.busHost = busHost;
      stateObj.busDriver = busDriver;
      if (
        Object.keys(bus).length &&
        Object.keys(busHost).length &&
        stateObj.tripStatus === "ACTIVE"
      ) {
        stateObj.tripStatus = "ASSIGNED";
      }

      if (Object.keys(bus).length && Object.keys(busHost).length) {
        const busType = await fetchBusType(bus.busTypeId);
        stateObj.bus = {
          ...bus,
          busType
        };
      }
      // if (tripDetails.isPseudoOnTrip && booking.departureDate > Date.now()) {
      //   stateObj.tripStatus = "ASSIGNED";
      // }
      const checkForTripPassenegerCompleted = tripDetails.busRoute.filter(
        obj => obj.stationId === booking.dropPoint.stationId
      )[0];

      if (checkForTripPassenegerCompleted.stationMarked === true) {
        stateObj.tripStatus = "COMPLETED";
      }
      stateObj.isAndroid = this.isRedirectRequired();
      if (booking.covidConsent) {
        // stateObj.appRedirect = this.isRedirectRequired();
      }
      // let timediff = d.getTime() - 15 * 60 * 1000;

      let isMajnuDropStation =
        booking.dropPoint.stationId == majnuKaTilaId[0] ||
        booking.dropPoint.stationId == majnuKaTilaId[1];

      let isMajnuBetweenRoute = tripDetails.busRoute.filter(
        obj =>
          obj.reachingTime >= booking.boardingPoint.time &&
          (obj.station._id == majnuKaTilaId[0] ||
            obj.station._id == majnuKaTilaId[1])
      );
      let checkForFoodCarousel = marketingCardsData.PLATINUM.filter(
        obj => obj._id !== foodCarouselId
      );
      let marketingCardsDataWithOutFood = {
        PLATINUM: checkForFoodCarousel
      };

      stateObj.marketingCardsData =
        Array.isArray(isMajnuBetweenRoute) &&
        isMajnuBetweenRoute.length !== 0 &&
        !isMajnuDropStation &&
        hours >= 11 &&
        hours <= 23
          ? marketingCardsData
          : marketingCardsDataWithOutFood;

      let boardingRouteCheck = tripDetails.busRoute.filter(
        obj => obj.station._id === booking.boardingPoint.stationId
      )[0];

      let boardingRoute = boardingRouteCheck;
      if (boardingRouteCheck.station.type == "VANPOINT") {
        boardingRoute = tripDetails.busRoute.filter(
          obj => obj.station._id === boardingRouteCheck.linkStationId
        )[0];

        booking.boardingPoint = {
          address: boardingRoute.station.address,
          city: boardingRoute.cityName,
          cityId: boardingRoute.station.cityId,
          lat: boardingRoute.station.lat,
          lng: boardingRoute.station.lng,
          name: boardingRoute.station.name,
          stationId: boardingRoute.stationId,
          time: boardingRoute.reachingTime
        };
      }

      let dropRouteCheck = tripDetails.busRoute.filter(
        obj => obj.station._id === booking.dropPoint.stationId
      )[0];

      if (dropRouteCheck.station.type == "VANPOINT") {
        let dropPoint = tripDetails.busRoute.filter(
          obj => obj.station._id === dropRouteCheck.linkStationId
        )[0];

        booking.dropPoint = {
          address: dropPoint.station.address,
          city: dropPoint.cityName,
          cityId: dropPoint.station.cityId,
          lat: dropPoint.station.lat,
          lng: dropPoint.station.lng,
          name: dropPoint.station.name,
          stationId: dropPoint.stationId,
          time: dropPoint.reachingTime
        };
      }

      tripDetails.busRoute = tripDetails.busRoute.filter(
        route => route.station.type !== "VANPOINT"
      );

      switch (stateObj.tripStatus) {
        case "ACTIVE": {
          stateObj.wayPoints = getWayPoints(
            tripDetails.busRoute,
            booking.boardingPoint.stationId,
            booking.dropPoint.stationId
          );

          break;
        }

        case "ASSIGNED": {
          stateObj.wayPoints = getWayPoints(
            tripDetails.busRoute,
            booking.boardingPoint.stationId,
            booking.dropPoint.stationId
          );

          break;
        }

        case "ONTRIP": {
          const lastlocation = await fetchLastLocation(booking.tripId);

          const upcomingStation = getStationFromStationId(
            lastlocation.upcomingStationId,
            tripDetails.busRoute
          );

          stateObj.boardingStationMarked = boardingRoute.stationMarked;

          stateObj.lastlocation = lastlocation;

          stateObj.upcomingStation = upcomingStation;
          stateObj.wayPoints = getWayPoints(
            tripDetails.busRoute,
            booking.boardingPoint.stationId,
            booking.dropPoint.stationId
          );
        }
      }

      this.setState(stateObj);
    } catch (error) {
      this.setState({ loading: false });
    }
  };
  handleScroll = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth"
    });
  };

  propHandler = () => {
    this.setState(prev => {
      return {
        tourValue: prev.tourValue + 1
      };
    });
  };
  getMarketingCards = async () => {
    try {
      const path = "/marketing/selfHelpPage?platform=SELFHELPPAGE";
      const options = {
        method: "get"
      };

      const marketingCards = await Fetch(path, options);

      return marketingCards;
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  getCustomerLatLng = async () => {
    try {
      const path = "/bus-tracker/customerLat";
      const options = {
        method: "get"
      };

      let response = await Fetch(path, options);
      this.setState({ customerLat: response });
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  isRedirectRequired = () => {
    const query = new URLSearchParams(this.props.location.search);
    return query.get("initiator") == "B2CANDROID";
  };

  onAgreeCovidConsent = async body => {
    try {
      const { booking } = this.state;

      const path = "/user/covidConsent";
      const options = {
        method: "post",
        data: {
          whatsAppConsent: body.whatsAppConsent,
          covidConsent: body.covidConsent,
          bookingId: this.state.bookingId
        }
      };

      const data = await Fetch(path, options);

      if (data) {
        this.setState({
          booking: { ...booking, covidConsent: true }
          // appRedirect: this.isRedirectRequired()
        });
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };
  onTourVisited = async () => {
    try {
      const { booking } = this.state;

      const path = "/user/tourVisited";
      const options = {
        method: "post",
        data: {
          bookingId: this.state.bookingId
        }
      };

      const data = await Fetch(path, options);

      if (data) {
        this.setState({
          booking: { ...booking, tourVisited: true }
          // appRedirect: this.isRedirectRequired()
        });
      }
    } catch (err) {
      console.log(err);
      message.error("Wrong");
    }
  };

  handleChange = obj => {
    this.setState(obj);
  };

  onPassengerBoarded = data => {
    const { booking } = this.state;

    if (!booking.isOnboarded && data.bookingCode === booking.bookingCode) {
      window.location.reload();
    }
  };

  updateETA = data => {
    const { booking, upcomingStation, busRoute, station } = this.state;
    let isfirststation =
      booking.boardingPoint.stationId === busRoute[0].station._id;
    let isStationMarked = busRoute[0].stationMarked;

    //if first station reaching time is or not
    let firstStationtimeCheck = busRoute[0].reachingTime > Date.now();
    let delay = false;
    let early = false;
    let delayTimeSec = 0;
    let earlyTimeSec = 0;
    let reachingTime = booking.boardingPoint.time;
    let expectedReachingTime = station.filter(
      obj => obj.station._id == booking.boardingPoint.stationId
    )[0].expectedReachingTime;

    if (booking.isOnboarded) {
      reachingTime = upcomingStation.reachingTime;
      expectedReachingTime = station.filter(
        obj => obj.station._id == booking.dropPoint.stationId
      )[0].expectedReachingTime;
    }
    if (
      expectedReachingTime < reachingTime &&
      !isfirststation &&
      isStationMarked &&
      !firstStationtimeCheck
    ) {
      early = true;
      earlyTimeSec = expectedReachingTime;
    }
    if (reachingTime < expectedReachingTime) {
      delay = true;
      delayTimeSec = expectedReachingTime;
    }

    this.setState({
      eta: {
        text: data.eta,
        etaSec: data.etaSec,
        delay,
        delayTimeSec,
        early,
        earlyTimeSec
      }
    });
  };

  expireSelfHelpNote = async booking => {
    const url = `/bus-tracker/removeSelfHelpNoteviaSelfHelp`;
    booking = {
      pnr: booking.pnr ? booking.pnr : booking.bookingCode,
      actionHistory: booking.actionHistory
    };
    const options = {
      method: "post",
      data: {
        bookings: booking
      }
    };

    await Fetch(url, options);
    this.fetchData();
  };

  toggleSOS = () => {
    this.setState({ showSOSModal: true });
  };

  raiseSOS = async () => {
    try {
      let { booking } = this.state;
      let url = `/booking/raiseSOS/`;

      const options = {
        method: "post",
        data: {
          bookingCode: booking.bookingCode
        }
      };

      const response = await Fetch(url, options);
      if (Array.isArray(response) && response.length > 0) {
        booking = { ...booking, isSOSAlertInserted: true };
        this.setState({ booking, showSOSModal: false });
      } else {
        message.error("Something went wrong");
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateUpcomingStation = stationId => {
    const { upcomingStation, busRoute, booking } = this.state;

    const station = getStationFromStationId(stationId, busRoute);

    if (upcomingStation.stationId !== station.stationId) {
      const stateObj = { upcomingStation: station };

      // Check if bus is Missed

      if (
        !booking.isOnboarded &&
        station.reachingTime > booking.boardingPoint.time
      ) {
        // Bus missed case

        stateObj.wayPoints = getWayPoints(
          busRoute,
          booking.boardingPoint.stationId,
          booking.dropPoint.stationId
        );

        stateObj.booking = { ...booking, isOnboarded: true };
      }

      this.setState(stateObj);
    }
  };

  getMap = () => {
    const {
      booking,
      wayPoints,
      tripStatus,
      lastlocation,
      upcomingStation,
      busRoute,
      station,
      isCheckService,
      TrackingWorking
    } = this.state;
    let isStationMarked = busRoute.filter(
      route => route.station._id == booking.boardingPoint.stationId
    )[0].stationMarked;
    if (isCheckService) {
      return (
        <Map
          booking={booking}
          isStationMarked={isStationMarked}
          wayPoints={wayPoints}
          tripStatus={tripStatus}
          onPassengerBoarded={this.onPassengerBoarded}
          lastlocation={lastlocation}
          station={station}
          updateETA={this.updateETA}
          checkLastTime={this.checkLastTime}
          isTrackingWorking={booking.trip.isTrackingWorking}
          TrackingWorking={TrackingWorking}
          upcomingStation={upcomingStation}
          updateUpcomingStation={this.updateUpcomingStation}
          tourValue={this.state.tourValue}
        />
      );
    } else {
      return (
        <OldMap
          booking={booking}
          wayPoints={wayPoints}
          tripStatus={tripStatus}
          onPassengerBoarded={this.onPassengerBoarded}
          lastlocation={lastlocation}
          updateETA={this.updateETA}
          isTrackingWorking={booking.trip.isTrackingWorking}
          upcomingStation={upcomingStation}
          updateUpcomingStation={this.updateUpcomingStation}
          tourValue={this.state.tourValue}
        />
      );
    }
  };

  getTrayContent = () => {
    const {
      booking,
      marketingCardsData,
      busRoute,
      tripStatus,
      bus,
      upcomingStation,
      boardingStationMarked,
      eta,
      busHost,
      cities,
      busDriver,
      customerLat,
      isAndroid,
      isConnectingBooking,
      connectingFirstLegCompleted,
      isCheckService
    } = this.state;
    const isCoreBooking =
      booking.demandSource == "B2CIOS" ||
      booking.demandSource == "B2CWEB" ||
      booking.demandSource == "B2CANDROID";
    let showBanner = true;
    // coupontime,
    // hrs,
    // min;
    // if (booking && booking.otaPartner == "redbus") {
    //   showBanner = false;
    // }
    // if (booking.returnCouponDetail) {
    //   const deadline = booking.dropPoint.time;
    //   coupontime = deadline - Date.now();

    //   hrs = Math.floor((coupontime / (1000 * 60 * 60)) % 24);
    //   min = Math.floor((coupontime / 1000 / 60) % 60);
    // }
    return (
      <>
        <div className={styles.trayCards}>
          {isCoreBooking ? <OnTimeBanner booking={booking} /> : null}
          <TripDetails
            isConnectingBooking={isConnectingBooking}
            boardingStationMarked={boardingStationMarked}
            connectingFirstLegCompleted={connectingFirstLegCompleted}
            // changeBooking={this.changeBooking}
            connectingFlag={this.state.connectingFlag}
            customerLatLng={customerLat}
            eta={eta}
            upcomingStation={upcomingStation}
            booking={booking}
            busRoute={busRoute}
            toggleOverlayScreen={this.toggleOverlayScreen}
            showStationChangeScreen={this.showStationChangeScreen}
            cities={cities}
            bus={bus}
            tourValue={this.state.tourValue}
          />

          {booking.returnCouponDetail ? (
            <ReturnCoupon
              booking={booking}
              // hrs={hrs}
              // mins={min}
              couponDetails={booking.returnCouponDetail}
            />
          ) : null}
          {/* {Object.keys(busHost).length > 0 ? (
              <CrewDetails busHost={busHost} busDriver={busDriver} />
            ) : null} */}
          <Utils
            booking={booking}
            busType={bus.busType}
            toggleOverlayScreen={this.toggleOverlayScreen}
          />
        </div>

        <div
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            marginRight: "auto"
          }}
        >
          <hr></hr>
        </div>
        {/* {!isAndroid && showBanner ? (
          <div className={styles.trayCards} onClick={this.openPlayStore}>
            <img src={signupImage} />
          </div>
        ) : null} */}
        {showBanner ? (
          <p
            style={{
              marginTop: "15px",
              fontSize: "13px",
              fontFamily: "SFPROTEXT",
              fontWeight: "bold",
              paddingRight: "15px",
              paddingLeft: "15px",
              marginRight: "auto"
            }}
          >
            BEST OFFERS FOR YOUR JOURNEY
          </p>
        ) : null}
        {showBanner
          ? marketingCardsPriority.map(key =>
              marketingCardsData[key] ? (
                <div
                  key={key}
                  className={cx(
                    styles.marketingCardsData,
                    styles.transparentBackground,
                    "p-0"
                  )}
                >
                  <MarketingBar
                    booking={booking}
                    data={marketingCardsData[key]}
                  />
                </div>
              ) : (
                <div key={key} />
              )
            )
          : null}
        <Footer booking={booking} />
      </>
    );
  };

  toggleOverlayScreen = (
    open,
    overlayScreenContent = null,
    overlayTitle = ""
  ) => {
    const stateObj = {
      openOverlayScreen: open,
      overlayScreenContent,
      overlayTitle
    };

    if (open) {
      stateObj.overlayScreenContent = overlayScreenContent;
      stateObj.overlayTitle = overlayTitle;
    }

    this.setState(stateObj);
  };

  showStationChangeScreen = (open, selectedStationOverlay = "") => {
    const stateObj = {
      openStationScreen: open,
      selectedStationOverlay
    };
    let { booking } = this.state;

    if (open) {
      stateObj.selectedStationOverlay = selectedStationOverlay;
    } else {
      if (isStage) {
        window.location.replace(
          `https://testtracker.zingmobility.com/customerTracking/${booking.id}`
        );
      } else {
        window.location.replace(
          `https://tracer.zingbus.com/customerTracking/${booking.id}`
        );
      }
    }

    this.setState(stateObj);
  };
  changeBooking = () => {
    let { connectingFlag, booking, isCheckService } = this.state;
    if (connectingFlag) {
      this.setState({
        connectingFlag: false,
        loading: true
      });
      this.fetchData(this.props.match.params.bookingId);
    } else if (isCheckService) {
      if (
        booking.connectingBooking.boardingPoint.time - 4 * 60 * 60 * 1000 >
        Date.now()
      ) {
        this.setState({ setIsModalVisible: true });
      } else {
        this.setState({ connectingFlag: true, loading: true });
        this.fetchData(booking.connectingBooking.id);
      }
    } else {
      this.setState({ connectingFlag: true, loading: true });
      this.fetchData(booking.connectingBooking.id);
    }
  };
  render() {
    const {
      loading,
      booking,
      openOverlayScreen,
      openStationScreen,
      selectedStationOverlay,
      tripStatus,
      overlayScreenContent,
      overlayTitle,
      eta,
      busRoute,
      upcomingStation,
      busMissed,
      busCatchPossible,
      isAndroid,
      appRedirect,
      busStartTime,
      connectingFlag,
      setIsModalVisible,
      isCheckService,
      showSOSModal = false,
      customerLat
    } = this.state;

    let checkCompleted = busRoute.filter(
      obj => obj.stationId === booking.dropPoint.stationId
    );
    if (loading) {
      return <Loader />;
    }
    let isTrackingWorking = "";
    // if (
    //   !isCheckService &&
    //   (tripStatus === "COMPLETED" || checkCompleted[0].stationMarked === true)
    // ) {
    //   return <TripCompleted />;
    // }

    // if (!isCheckService && busMissed) {
    //   return <BusMissed />;
    // }
    if (!!booking.trip.isTrackingWorking == false) {
      isTrackingWorking = true;
    }

    // if (!booking.whatsappConsent && !booking.covidConsent) {
    //   return <CovidConsentForm onAgree={this.onAgreeCovidConsent} />;
    // }

    if (appRedirect) {
      return <AppRedirectScreen booking={booking} />;
    }
    if (isCheckService) {
      return (
        <div className={styles.selfHelpContainer}>
          {/* {!connectingFlag && booking.connectingBooking ? (
            <Modal
              centered
              width={"80%"}
              visible={setIsModalVisible}
              onOk={this.handleOk}
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{
                style: {
                  background: "#7b2bff",
                  right: "40%",
                  border: "none"
                }
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "SFProText",
                  textAlign: "center",
                  fontWeight: "400"
                }}
              >
                Hey, {booking.name}! Your tracking link for next trip will be
                active from{" "}
                {moment(
                  booking.connectingBooking.boardingPoint.time -
                    4 * 60 * 60 * 1000
                ).format("Do MMMM YYYY, h:mm:ss a")}
              </p>
            </Modal>
          ) : null} */}

          {this.state.tourValue === 3 ? (
            <div className={styles.arrow} id="arrow">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : null}
          <Header
            isAndroid={isAndroid}
            booking={booking}
            updateNote={this.expireSelfHelpNote}
            SOS={this.toggleSOS}
          />

          <div className={styles.contentContainer}>
            {openOverlayScreen ? (
              <OverlayScreen
                toggleOverlayScreen={this.toggleOverlayScreen}
                overlayTitle={overlayTitle}
              >
                {overlayScreenContent()}
              </OverlayScreen>
            ) : openStationScreen ? (
              <StationChangeScreen
                customerLatLng={customerLat}
                booking={booking}
                toggleStationScreen={this.showStationChangeScreen}
                overlayTitle={selectedStationOverlay}
              />
            ) : (
              <Screen
                map={this.getMap}
                tray={this.getTrayContent}
                // hideMap={!booking.trip.isTrackingWorking}
                tracking={!isTrackingWorking}
              />
            )}
          </div>

          <Modal
            centered
            width={"80%"}
            visible={showSOSModal}
            onOk={this.handleOk}
            footer={null}
            onCancel={() => this.setState({ showSOSModal: false })}
          >
            <div className={styles.SOSModal}>
              <p>Initiate SOS </p>
              <p>Are you sure you want to initiate SOS?</p>
              <p>
                Initiate SOS services only in case of emergency. Once SOS is
                initiated our team will try to reach you as soon as possible
              </p>
              <div onClick={this.raiseSOS}>
                <span>Inititae SOS</span>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div className={styles.selfHelpContainer}>
          {this.state.tourValue === 3 ? (
            <div className={styles.arrow} id="arrow">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : null}

          {/* {!booking.tourVisited && !booking.isOnboarded ? (
            <div className={styles.tourstyle}>
              <Tour
                handler={this.propHandler}
                tourValue={this.state.tourValue}
                onTourVisited={this.onTourVisited}
              />
            </div>
          ) : null} */}
          {busMissed ||
          tripStatus === "COMPLETED" ||
          checkCompleted[0].stationMarked === true ? null : (
            <OldHeader
              showEta={Object.keys(this.state.lastlocation).length}
              eta={eta}
              busMissed={busCatchPossible}
              busRoute={busRoute}
              upcomingStation={upcomingStation}
              booking={booking}
            />
          )}

          {/* {!booking.trip.isTrackingWorking ? (
            <div className={styles.tracking}>
              Tracking is not working for this bus, sorry for the inconvenience
            </div>
          ) : null} */}
          {tripStatus === "ACTIVE" && !isTrackingWorking ? null : tripStatus ===
            "ASSIGNED" ? (
            <div className={styles.busNotStartedInfo}>
              {`Bus will start from start point at ${moment(
                busStartTime
              ).format("MMM DD, HH:mm A")}`}
            </div>
          ) : tripStatus === "ACTIVE" ? (
            <div className={styles.busNotStartedInfo}>
              Journey Details will be shared atleast 4 hours prior to trip
            </div>
          ) : null}
          <div className={styles.contentContainer}>
            {!openOverlayScreen ? (
              <OldScreen
                map={this.getMap}
                tray={this.getTrayContent}
                tracking={!isTrackingWorking}
              />
            ) : (
              <OldOverlayScreen
                toggleOverlayScreen={this.toggleOverlayScreen}
                overlayTitle={overlayTitle}
              >
                {overlayScreenContent()}
              </OldOverlayScreen>
            )}
          </div>
        </div>
      );
    }
  }
}

export default SelfHelp;

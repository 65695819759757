import React from "react";
import styles from "./coupon.module.scss";
import { useState, useEffect } from "react";
import { mixPanelToken, isStage } from "../../../../config";
import moment from "moment";
import { message } from "antd";
import mixpanel from "mixpanel-browser";

const ReturnCoupon = props => {
  const { couponDetails } = props;
  const [copied, setIsCouponCopied] = useState(false);
  const { booking } = props;
  // const [hours, setHours] = useState(hrs);
  // const [minutes, setMinutes] = useState(mins);

  // const toStationTime = booking.toStationTime
  //   ? booking.toStationTime
  //   : booking.dropPoint.time;
  // const deadline = toStationTime;

  // const getTime = () => {
  //   const time = deadline - Date.now();

  //   setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
  //   setMinutes(Math.floor((time / 1000 / 60) % 60));
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => getTime(deadline), 1000);

  //   return () => clearInterval(interval);
  // }, []);

  const redirect = () => {
    if (copied) {
      mixpanel.init(mixPanelToken, { debug: isStage });
      mixpanel.track("Book Now with Return Coupon", {
        mobile_no: booking.mobileNo,
        trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
        pnr: booking.bookingCode,
        from_city: booking.boardingPoint.city,
        to_city: booking.dropPoint.city,
        from_station: booking.boardingPoint.address,
        to_station: booking.dropPoint.address,
        booking_source: booking.demandSource
      });
    }
    const url = "https://zingbusbtoc.page.link/fnb2";
    window.open(url, "_blank");
  };

  function couponCopy(couponCode) {
    navigator.clipboard
      .writeText(couponCode)
      .then(() => {
        message.success("Coupon is copied");
        setIsCouponCopied(true);
        mixpanel.init(mixPanelToken, { debug: isStage });
        mixpanel.track("Copy Return Discount Code", {
          mobile_no: booking.mobileNo,
          trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
          pnr: booking.bookingCode,
          from_city: booking.boardingPoint.city,
          to_city: booking.dropPoint.city,
          from_station: booking.boardingPoint.address,
          to_station: booking.dropPoint.address,
          booking_source: booking.demandSource
        });
      })
      .catch(error => {
        console.error(`Could not copy text: ${error}`);
      });
  }

  return (
    <div className={styles.bannerwrapper}>
      <div className={styles.bannerDetails}>
        <div>
          <p>
            Claim{" "}
            <b>
              {couponDetails.couponValue}
              {couponDetails.couponType == "PERCENTAGE" ? "%" : ""} OFF{" "}
            </b>
            on your upcoming adventure
          </p>
          <p>Apply coupon code at checkout *</p>
        </div>
        <div>
          <img src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/selfhelp/stickyNote.svg" />
        </div>
      </div>
      <img
        className={styles.bg}
        src="https://d1flzashw70bti.cloudfront.net/original/test/tracer/selfHelp/returnCouponBg.svg"
      />
      <div className={styles.expire}>
        <div>
          <div
            className={styles.copy}
            onClick={() => {
              couponCopy(couponDetails.couponCode);
            }}
          >
            <span id="coupon">Use : {couponDetails.couponCode}</span>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/copy.svg"
              alt="copy"
            ></img>
          </div>
        </div>
        <div className={styles.bookNow}>
          <p onClick={() => redirect()}>Book now</p>
        </div>
      </div>
      <img
        className={styles.clock}
        src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/selfHelp/clock.svg"
      />
    </div>
  );
};

export default ReturnCoupon;

import React, { Component, useState } from "react";
import cx from "classnames";
import support from "../../../../images/support.png";
import phoneNumber from "../../../../images/phoneImage.svg";
import styles from "./utils.module.scss";
import moment from "moment";
import { googleLayerAnalytics } from "../../../../utils/googleDataLayer";
import mixpanel from "mixpanel-browser";
import { mixPanelToken, isStage } from "../../../../config";

import { useHistory } from "react-router-dom";
import { Modal } from "antd";
const Utils = props => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { booking, toggleOverlayScreen, busType } = props;
  const history = useHistory();

  const handleOnClick = () => {
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Scan QR Code Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    toggleOverlayScreen(true, showQRCode, "QR Code");
    history.push("qr");
  };
  const showQRCode = () => (
    <img src={booking.boardingQr} width="100%" height="100%" />
  );

  const openWhatsApp = () => {
    googleLayerAnalytics("initiate_chat", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Chat Support Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    window.open(
      "https://api.whatsapp.com/send?phone=918287009889&text=zingbus"
    );
  };
  const callMarshall = () => {
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Call Marshall Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD")
    });
    if (booking.boardingPoint.time - 3 * 60 * 60 * 1000 < Date.now()) {
      window.open("tel:+91-8287009889");
    } else {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div
      style={{
        padding: "4%"
      }}
    >
      <li className="row">
        <div
          onClick={callMarshall}
          className="col-4 border-right"
          style={{
            fontSize: "13px",
            padding: "0px",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            fontFamily: "SFProText"
          }}
        >
          <img style={{ margin: "5%" }} src={phoneNumber} alt="phn Image" />
          {busType == "SUV" || busType == "TEMPOTRAVELLER"
            ? "Call Driver"
            : "Call Marshal"}{" "}
        </div>
        <div
          className="col-5 border-right"
          onClick={openWhatsApp}
          style={{
            padding: "0px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            fontFamily: "SFProText"
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              fontFamily: "SFProText"
            }}
          >
            <img
              src={support}
              style={{ paddingRight: "5px" }}
              alt="CallSupport Image"
            />
            <div
              style={{
                marginLeft: "1px",
                fontSize: "13px",
                fontFamily: "SFPROTEXT"
              }}
            >
              Support
            </div>
          </div>
          <div style={{ fontSize: "9px", fontFamily: "SFProText" }}>
            Get reply within 30 sec
          </div>
        </div>
        <div
          className="col-3"
          style={{
            padding: "0px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            fontFamily: "SFProText"
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "13px",
              fontFamily: "SFPROTEXT"
            }}
          >
            OTP: {booking.boardingOtp}
          </div>
          <button
            className="blue"
            style={{
              fontSize: "9px",
              backgroundColor: "transparent",
              backgroundRepeat: "no-repeat",
              border: "none",
              cursor: "pointer",
              overflow: "hidden",
              outline: "none",
              width: "100%"
            }}
            onClick={handleOnClick}
          >
            Scan QR code
          </button>
        </div>
      </li>
      <div style={{ width: "20px" }}>
        <Modal
          className={styles.modal}
          centered
          width={"80%"}
          visible={isModalVisible}
          onOk={handleOk}
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{
            style: {
              background: "#7b2bff",
              right: "40%",
              border: "none"
            }
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontFamily: "SFProText",
              textAlign: "center",
              fontWeight: "400"
            }}
          >
            Hey, {booking.name}! Our Marshal's contact Number will be active at{" "}
            {moment(booking.boardingPoint.time - 3 * 60 * 60 * 1000).format(
              "Do MMMM YYYY, h:mm:ss a"
            )}
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default Utils;
